<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    >
      <v-btn
        v-if="!readOnly"
        slot="right"
        icon
        @click.stop="clickSubmit()"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content>
      <div
        v-show="imageIsLoaded"
        class="canvas-container"
        scrolling="touch"
      >
        <material-sistema-plano-imagen
          class="pa-3"
          :item="formattedItem"
          @image-loaded="imageLoaded"
        />
        <canvas
          ref="MaterialSistemaCanvas"
          class="material-sistema-canvas"
          v-on="!readOnly ? {
            mousedown: handleMouseDown,
            mousemove: handleMouseMove,
            mouseup: handleMouseUp,
            touchstart: handleTouchStart,
            touchmove: handleTouchMove,
            touchend: handleTouchEnd,
          } : {
            mousedown: handleMouseDownReadOnly,
          }"
        />
        <b10-fab-button
          v-if="readOnly"
          :icon="$vuetify.icons.values.edit"
          @click="clickEdit"
        />
        <material-sistema-plano-menu
          :showing="showingDialogs.showingMenuMaterial"
          :item="elementoArrastrado"
          :read-only="readOnly"
          @delete="clickDeleteConfirm"
          @ver-material="clickVerMaterial"
          @close-menu-material="closeShowingMenuMaterial"
        />
        <material-sistema-plano-add
          :showing.sync="showingDialogs.materialPlanoAdd"
          :idsistema="routeParams.idsistema"
          :subsistemas="subsistemas"
          :idsubsis="idsubsis"
          :materiales-sistema-form="materialesSistemaForm"
          @materiales-add-form-data="materialesAddFormData"
          @load-material-sistema-form="loadMaterialSistemaForm"
        />
        <material-sistema-plano-legend
          :showing.sync="showingDialogs.legends"
          :colores-tipo-subfamilia="coloresTipoSubfamilia"
        />
        <b10-fab-button
          v-if="!readOnly"
          @click="clickAddMaterial"
        />
      </div>
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import { extractFileName } from '@/utils/files'
import Data from './MaterialSistemaPlanoData'
import MaterialSistemaPlanoMenu from './components/MaterialSistemaPlanoMenu'
import MaterialSistemaPlanoAdd from '../materialSistemaPlanoAdd/MaterialSistemaPlanoAdd'
import MaterialSistemaPlanoLegend from './components/MaterialSistemaPlanoLegend'
import MaterialSistemaPlanoImagen from './components/MaterialSistemaPlanoImagen'
import { delphiColorToHex, delphiColorToRgba } from '@/utils/ui'
import { MATERIAL_SISTEMA } from '@/utils/consts'

export default {
  components: {
    MaterialSistemaPlanoImagen, MaterialSistemaPlanoMenu, MaterialSistemaPlanoAdd, MaterialSistemaPlanoLegend
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
    editRoute: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      showingDialogs: {
        showingMenuMaterial: false,
        materialPlanoAdd: false,
        legends: false,
      },
      toolbarOptions: {
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info',
        },
      },
      coloresTipoSubfamilia: [],
      subsistemas: [],
      idsubsis: null,
      materialesSistema: [],
      materialesSistemaForm: [],
      nuevaPosicionMaterialesSistema: [],
      elementoArrastrado: null,
      canvasWidth: 0,
      canvasHeight: 0,
      longPressTimer: null,
      imageIsLoaded: false,
      cicleRadius: 20,
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idfichero
        item.subtitle = item.tfichero_descripcion
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = extractFileName(this.item.dataset.nombre_fichero)
    },
    async loadItem () {
      this.loadingData = true
      try {
        const resp = await Data.selectDetail(this, this.routeParams.idsistema, this.routeParams.idfichero)
        if (resp.data.selectFicheroPlano) {
          const dataset = resp.data.selectFicheroPlano.result.dataset[0]
          dataset['blobUrl'] = await this.$api.getBlobUrl(dataset)
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', resp.data.selectFicheroPlano.result.metadata)
          this.materialesSistema = resp.data.selectMaterialesSistema.result.dataset
          // Colores para la leyenda, hago un distinct de tsubfamilia
          const camposTsubfamiliaColor = _.map(this.materialesSistema , (material) => ({
            tsubfamilia_desc: material.tsubfamilia_desc,
            color: `#${delphiColorToHex(material.color)}`,
          }))
          this.coloresTipoSubfamilia = _.uniqBy(camposTsubfamiliaColor, (material) => material.tsubfamilia_desc && material.color)
          this.subsistemas = resp.data.selectSubsis.result.dataset
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async imageLoaded (data) {
      this.imageIsLoaded = true
      this.canvasHeight = data.canvasHeight
      this.canvasWidth = data.canvasWidth
      await this.loadCanvas()
    },

    async loadCanvas() {
      const canvas = this.$refs.MaterialSistemaCanvas
      const context = canvas.getContext("2d")
      // Limpiar el canvas
      context.clearRect(0, 0, this.canvasHeight, this.canvasWidth)
      canvas.width = this.canvasWidth
      canvas.height = this.canvasHeight
      // Renderizar los materiales en el canvas
      await this.renderMateriales(context)
    },

    async renderMateriales(context) {
      for (const material of this.materialesSistema) {
        const colorMaterial = delphiColorToHex(material.color)
        const colorMaterialRGBA = delphiColorToRgba(material.color, 0.5)
        let pos_x, pos_y
        if (material.posicion_plano_x) {
          pos_x = material.posicion_plano_x
          pos_y = material.posicion_plano_y
        } else {
          pos_x = 10
          pos_y = 10
        }
        // Dibujar el círculo
        context.beginPath()
        context.arc(pos_x, pos_y, this.cicleRadius, 0, 2 * Math.PI)
        context.fillStyle = colorMaterialRGBA
        context.fill()

        // Dibujar el borde sólido del círculo
        context.lineWidth = 4
        context.strokeStyle = `#${colorMaterial}`
        context.stroke()

        // Configurar el estilo del texto
        context.fillStyle = MATERIAL_SISTEMA.plano.texto.fillStyle
        context.font = MATERIAL_SISTEMA.plano.texto.font
        context.textAlign = MATERIAL_SISTEMA.plano.texto.textAlign
        context.textBaseline = MATERIAL_SISTEMA.plano.texto.textBaseline
        context.fillText(this.$online.materialSistema.codigoIdentificacion(material), pos_x, pos_y)
      }
    },

    getCanvasCoordinates(event) {
      const rect = this.$refs.MaterialSistemaCanvas.getBoundingClientRect()
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top
      return { x, y }
    },
    isInsideCircle(x, y, circleX, circleY) {
      const dx = x - circleX
      const dy = y - circleY
      return dx * dx + dy * dy <= this.cicleRadius * this.cicleRadius
    },
    handleMouseDown(event) {
      const { x, y } = this.getCanvasCoordinates(event)
      this.showingDialogs.showingMenuMaterial = false
      for (const material of this.materialesSistema) {
        if (this.isInsideCircle(x, y, material.posicion_plano_x, material.posicion_plano_y)) {
          this.elementoArrastrado = material
          this.elementoArrastrado.posicionInicialX = material.posicion_plano_x
          this.elementoArrastrado.posicionInicialY = material.posicion_plano_y
          document.addEventListener('touchmove', this.disableScroll, { passive: false })
          // mostrar menú conceptual
          // Iniciar temporizador para la pulsación larga
          this.longPressTimer = setTimeout(() => {
            if (this.elementoArrastrado) {
              this.showingDialogs.showingMenuMaterial = true
            }
          }, 600) // Duración de la pulsación larga en milisegundos
          break
        }
      }
    },

    handleMouseDownReadOnly(event) {
      const { x, y } = this.getCanvasCoordinates(event)
      for (const material of this.materialesSistema) {
        if (this.isInsideCircle(x, y, material.posicion_plano_x, material.posicion_plano_y)) {
          this.elementoArrastrado = material
          this.showingDialogs.showingMenuMaterial = true
          break
        } else {
          this.showingDialogs.showingMenuMaterial = false
        }
      }
    },

    handleTouchStart(event) {
      const touch = event.touches[0]
      this.handleMouseDown(touch)
    },

    handleMouseMove(event) {
      if (this.elementoArrastrado && !this.showingDialogs.showingMenuMaterial) {
        // Cancelar temporizador para la pulsación larga si se mueve el elemento
        clearTimeout(this.longPressTimer)
        const { x, y } = this.getCanvasCoordinates(event)
        const offsetX = x - this.elementoArrastrado.posicionInicialX
        const offsetY = y - this.elementoArrastrado.posicionInicialY
        this.elementoArrastrado.posicion_plano_x = this.elementoArrastrado.posicionInicialX + offsetX
        this.elementoArrastrado.posicion_plano_y = this.elementoArrastrado.posicionInicialY + offsetY
        this.loadCanvas()
      }
    },

    handleTouchMove(event) {
      const touch = event.touches[0]
      this.handleMouseMove(touch)
    },

    async handleMouseUp() {
      if (this.elementoArrastrado) {
        // Hago que no se mueva el canvas.
        document.removeEventListener('touchmove', this.disableScroll)
        const materialIndex = this.nuevaPosicionMaterialesSistema.findIndex(
          material => material.idmaterial_sistema === this.elementoArrastrado.idmaterial_sistema
        )
        if (materialIndex === -1) {
          // Si el material no existe en el array, crear un nuevo objeto con las posiciones y guardarlo
          const nuevoMaterial = {
            idmaterial_sistema: this.elementoArrastrado.idmaterial_sistema,
            posicion_plano_x: this.elementoArrastrado.posicion_plano_x,
            posicion_plano_y: this.elementoArrastrado.posicion_plano_y
          }
          this.nuevaPosicionMaterialesSistema.push(nuevoMaterial)
        } else {
          // Si el material ya existe, actualizar las posiciones
          this.nuevaPosicionMaterialesSistema[materialIndex].posicion_plano_x = this.elementoArrastrado.posicion_plano_x
          this.nuevaPosicionMaterialesSistema[materialIndex].posicion_plano_y = this.elementoArrastrado.posicion_plano_y
        }
      }
      // Cancelar temporizador para la pulsación larga
      clearTimeout(this.longPressTimer)
      if (!this.showingDialogs.showingMenuMaterial) {
        this.elementoArrastrado = null
      }
    },

    handleTouchEnd() {
      this.handleMouseUp()
    },

    disableScroll(event) {
      event.preventDefault();
    },
    async clickSubmit () {
      if (this.nuevaPosicionMaterialesSistema.length > 0) {
        await Data.guardarMaterial(this, this.nuevaPosicionMaterialesSistema, this.routeParams.idfichero)
      }
      this.$appRouter.go(-1)
    },

    clickEdit () {
      this.$appRouter.push({
        name: this.editRoute,
        params: {
          idsistema: this.routeParams.idsistema,
          idfichero: this.routeParams.idfichero,
        },
      })
    },

    async clickDeleteConfirm (data) {
      this.showingDialogs.showingMenuMaterial = false
      const res = await this.$alert.showConfirm(
        `¿Deseas quitar el elemento "${data.title}" del plano?`
      )
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          // ver si no hacer esto en una transacción, para poder darle para atrás y deshacer los cambios
          await Data.borrarDelPlano(this, data.idmaterialSistema)
          const materialesSistemaActualizado = _.filter(this.materialesSistema, (obj) => obj.idmaterial_sistema !== data.idmaterialSistema)
          this.materialesSistema = materialesSistemaActualizado
          this.nuevaPosicionMaterialesSistema = materialesSistemaActualizado
          this.loadCanvas()
        } finally {
          this.$loading.hide()
        }
      }
    },

    clickVerMaterial (data) {
      this.showingDialogs.showingMenuMaterial = false
      this.$appRouter.push({
        name: 'sistemas__material-sistema-view',
        params: {
          idsistema: this.routeParams.idsistema,
          idsubsis: data.item.idsubsis,
          idmaterial_sistema: data.item.idmaterial_sistema,
        },
      })
    },

    clickAddMaterial () {
      this.materialesSistemaForm = []
      if (this.subsistemas.length === 1) {
        this.idsubsis = this.subsistemas[0].idsubsis
      }
      this.showingDialogs.materialPlanoAdd = !this.showingDialogs.materialPlanoAdd
    },

    materialesAddFormData (data) {
      const factorPosicion = 15
      for (let i = 0; i < data.items.length; i++) {
        // Establecer posición inicial en cascada.
        const item = data.items[i]
        item.posicion_plano_x = (i + 1) * factorPosicion
        item.posicion_plano_y = (i + 1) * factorPosicion
      }
      this.materialesSistema = [ ...this.materialesSistema, ...data.items ]
      this.loadCanvas()
      this.idsubsis = null
    },
    async loadMaterialSistemaForm (data) {
      const excludePks = _.map(this.materialesSistema, 'idmaterial_sistema')
      this.materialesSistemaForm = await Data.selectMaterialSistema(this, data.idsubsis, excludePks)
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      }
    },
    closeShowingMenuMaterial (data) {
      this.showingDialogs.showingMenuMaterial = data
      this.elementoArrastrado = null
    }
  },
}
</script>

<style scoped>
.canvas-container {
  overflow: auto;
  width: 100%;
  height: 100vh;
  position: relative;
}

.background-canvas,
.material-sistema-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.background-canvas {
  z-index: 1;
}

.material-sistema-canvas {
  z-index: 2;
}
</style>