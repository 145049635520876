import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectDetail (Vue, idsistema, idfichero) {
    let apiCalls = []
    const apiFilterFichero = new APIFilter()
    apiFilterFichero.addExact('idfichero', idfichero)
    apiCalls.push({
      name: 'selectFicheroPlano',
      method: 'fichero.select',
      params: { filter: apiFilterFichero },
    })
    const apiFilterMaterialesSistema = new APIFilter()
    apiFilterMaterialesSistema
      .addExact('idsistema', idsistema)
      .addIsNull('posicion_plano_x', true)
      .addGT('estado', 0)
      .addIsNull('fdesinstalacion', false)
      .addExact('idfichero_plano', idfichero)
    apiCalls.push({
      name: 'selectMaterialesSistema',
      method: 'materialSistema.select',
      params: { filter: apiFilterMaterialesSistema },
    })
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
      .addExact('idsistema', idsistema)
    apiCalls.push({ name: 'selectSubsis', method: 'subsis.select', params: { filter: apiFilter } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async guardarMaterial (Vue, materiales, idfichero) {
    let apiCalls = []
    for (const material of materiales) {
      apiCalls.push({
        name: `updateMaterialSistema_${material.idmaterial_sistema}`,
        method: 'materialSistema.update',
        params: {
          values: {
            idmaterial_sistema: material.idmaterial_sistema,
            posicion_plano_x: material.posicion_plano_x,
            posicion_plano_y: material.posicion_plano_y,
            idfichero_plano: idfichero,
          },
        }
      })
    }
    return await Vue.$api.batchCall(apiCalls)
  },
  async borrarDelPlano (Vue, idmaterialSistema) {
    await Vue.$api.call(
      'materialSistema.update',
      {
        values: {
          idmaterial_sistema: idmaterialSistema,
          posicion_plano_x: null,
          posicion_plano_y: null,
          idfichero_plano: null,
        },
      },
    )
  },
  async selectMaterialSistema (Vue, idsubsis, excludePks) {
    // excludePks son materiales que ya están en el plano, pero no tienen guardado en base de datos el valor en posicion_plano_x
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('estado', 1)
      .addExact('idsubsis', idsubsis)
      .addIsNull('posicion_plano_x', false)
      .addExact('esta_abonado', 0)
    if (excludePks.length > 0) {
      apiFilter
        .addIn('idmaterial_sistema', excludePks, true)
    }
    const resp = await Vue.$api.call(
      'materialSistema.select',
      {
        filter: apiFilter,
      },
    )
    return resp.data.result.dataset
  },
}
